import React, { memo, useCallback, useMemo, useState } from "react";
// eslint-disable-next-line import/no-unresolved
import { react, useChanged } from "uikit";
import "leaflet-polylinedecorator";
import MarkerController from "react-leaflet-enhanced-marker";
import PropsOf from "uikit/src/types/PropsOf";
import { useMap } from "react-leaflet";
import Color from "color";
import { useTranslation } from "react-i18next";

import { Executor, ExecutorLocations } from "../../services";
import { ExecutorStatus } from "../../services/Executor";
import OrderSource from "../../types/OrderSource";
import { useTypedSelector } from "../../redux/store";
import useExecutorStatus from "../../hooks/useExecutorStatus";

import LocalController from "./Controller";
import CarMarker from "./components/CarMarker";
import {
	ExecutorInfo,
	getBalance,
	getCar,
	getLastOrder,
	getPersonFullName,
	getPersonPhones,
	getPriority,
	getRating,
} from "./components/ExecutorInfo";

const executorStatusToColor = {
	[ExecutorStatus.AVAILABLE]: "#4baf4f",
	[ExecutorStatus.BUSY]: "#ff9d48",
	[ExecutorStatus.DINNER]: "#40a4f4",
	[ExecutorStatus.HOME]: "#3d50b2",
	[ExecutorStatus.CLOSED]: "#7b3636",
};

const MapExecutorLocationsBase = react.withController<
	MapExecutorLocations.PropsBase,
	LocalController
>(({ controller, executorLocations, executors }) => {
	const map = useMap();
	const lang = useTypedSelector((state) => state.session.language);
	const { t } = useTranslation();
	const { getStatus } = useExecutorStatus();

	const [focusedExecutorId, setFocusedExecutorId] = useState<
		number | undefined
	>(undefined);

	const getOnlineStatus = useCallback(
		(data) =>
			data.online
				? t("orderPageWidgets.parking.parkingTable.str200") ?? ""
				: t("orderPageWidgets.parking.parkingTable.str201") ?? "",
		[t],
	);

	const kph = useMemo(() => t("units.kph"), [t]);

	const getExecutorById = useCallback(
		(id: number, speed: number) => {
			const exist = executors?.find((item) => item?.id === id);
			const car = getCar(exist, lang);
			const time = speed || 0;
			const modifySpeed = Number(time * 3.6).toFixed(1);

			const payload = {
				alias: exist?.alias || "",
				online: getOnlineStatus(exist),
				status: getStatus(exist),
				priority: getPriority(exist),
				speed: `${modifySpeed} ${kph}`,
				carType: car.carType,
				carData: car.carData,
				phones: getPersonPhones(exist),
				balance: getBalance(exist),
				fullName: getPersonFullName(exist),
				order: getLastOrder(exist),
				rating: getRating(exist),
			};

			return payload;
		},
		[executors, getOnlineStatus, getStatus, lang, kph],
	);

	const markers = useMemo(
		() =>
			executorLocations?.map((executorLocation) => {
				const onOrder = executorLocation.executor.hasActiveOrder;
				let onOrderStatus: "ownOrder" | "otherOrder" | null = null;

				if (onOrder) {
					onOrderStatus = executorLocation.executor.orders?.some(
						(order) =>
							order.source === OrderSource.EXECUTOR &&
							order.status !== "closed",
					)
						? "ownOrder"
						: "otherOrder";
				}

				let mainColor: string;
				let secondaryColor: string;

				if (!executorLocation.executor.isWorking || onOrder) {
					secondaryColor =
						executorStatusToColor[
							executorLocation.executor
								.status as keyof typeof executorStatusToColor
						] ?? "#fff509";

					if (!executorLocation.executor.isWorking) {
						mainColor = "#a0a0a0";
						secondaryColor = Color(secondaryColor)
							.lighten(0.25)
							.hex();
					} else {
						mainColor =
							onOrderStatus === "ownOrder"
								? "#654cad"
								: "#e5503b";
					}
				} else {
					mainColor =
						executorStatusToColor[executorLocation.executor.status];
					secondaryColor = "#fff509";
				}

				if (executors?.length) {
					const executor = getExecutorById(
						executorLocation.executorId,
						executorLocation.speed,
					);

					return (
						<MarkerController
							key={executorLocation.id}
							icon={
								<CarMarker
									focused={
										executorLocation.executor.id ===
										focusedExecutorId
									}
									mainColor={mainColor}
									secondaryColor={secondaryColor}
									alias={executorLocation.executor.alias}
								/>
							}
							draggable={false}
							position={executorLocation.point}
						>
							<ExecutorInfo executor={executor} />
						</MarkerController>
					);
				}

				return (
					<MarkerController
						key={executorLocation.id}
						icon={
							<CarMarker
								focused={
									executorLocation.executor.id ===
									focusedExecutorId
								}
								mainColor={mainColor}
								secondaryColor={secondaryColor}
								alias={executorLocation.executor.alias}
							/>
						}
						draggable={false}
						position={executorLocation.point}
					/>
				);
			}),
		[
			executorLocations,
			executors?.length,
			focusedExecutorId,
			getExecutorById,
		],
	);

	controller.setContext({
		setFocusedExecutorId,

		map,
		executorLocations,
		focusedExecutorId,
	});

	useChanged(() => {
		controller.focus();
	}, focusedExecutorId);

	return <>{markers}</>;
}, LocalController);

const MapExecutorLocations = memo(MapExecutorLocationsBase);

declare namespace MapExecutorLocations {
	type Controller = LocalController;

	interface PropsBase {
		executorLocations: ExecutorLocations.Model[];
		executors?: Executor.Model[];
	}

	type Props = PropsOf<typeof MapExecutorLocations>;
}

export default MapExecutorLocations;
