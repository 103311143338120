import React, { useLayoutEffect, useMemo } from "react";

import {
	CarClass,
	Service,
	TaxiService,
	Executor,
	ExecutorLocations,
	ExecutorGroup,
	ExecutorRate,
	Role,
} from "../../services";
import { ExecutorSubscribeType } from "../../services/Executor";
import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import { preferences, globalState } from "../../redux/reducers";
import taxiServices from "../../redux/reducers/taxiServices";
import { useModelSubscribe } from "../../hooks";

const BaseModelsLoader: React.FC<BaseModelsLoader.Props> = () => {
	const dispatch = useTypedDispatch();

	const connected = useTypedSelector((state) => state.prpc.connected);
	const authorized = useTypedSelector((state) => state.prpc.authorized);
	const language = useTypedSelector((state) => state.session.language);

	const canSubscribe: boolean = useMemo(
		() => connected && authorized,
		[connected, authorized],
	);

	const subscribeTaxiService = useModelSubscribe({}, TaxiService);
	const subscribeCarClass = useModelSubscribe({}, CarClass);
	const subscribeService = useModelSubscribe({}, Service);
	const subscribeExecutor = useModelSubscribe(
		{
			language,
			subscribeType: ExecutorSubscribeType.FRONT,
		},
		Executor,
	);
	const subscribeExecutorLocations = useModelSubscribe(
		{ revealExecutor: true },
		ExecutorLocations,
	);
	const subscribeExecutorGroup = useModelSubscribe(
		{ lang: language },
		ExecutorGroup,
	);
	const subscribeExecutorRate = useModelSubscribe(
		{ lang: language },
		ExecutorRate,
	);
	const subscribeRole = useModelSubscribe({ language }, Role);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeService.models : [];
		dispatch(preferences.services.data.setModels(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeService.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeCarClass.models : [];
		dispatch(preferences.carClasses.data.setModels(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeCarClass.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeTaxiService.models : [];
		dispatch(taxiServices.actions.setModels(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeTaxiService.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutor.models : [];
		dispatch(globalState.executors.data.setSubscriptionBasicData(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutor.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutorLocations.models : [];
		dispatch(
			globalState.executorLocations.data.setSubscriptionBasicData(models),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutorLocations.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutorGroup.models : [];
		dispatch(
			globalState.executorGroup.data.setSubscriptionBasicData(models),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutorGroup.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutorRate.models : [];
		dispatch(
			globalState.executorRate.data.setSubscriptionBasicData(models),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutorRate.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeRole.models : [];
		dispatch(globalState.role.data.setSubscriptionBasicData(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeRole.models]);

	return <></>;
};

declare namespace BaseModelsLoader {
	interface Props {}
}

export default BaseModelsLoader;
