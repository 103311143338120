/* eslint-disable no-shadow */

import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button, Flex, Grid, GridDragArea } from "uikit";
import { SortType } from "rsuite-table";

import { Car, Executor } from "../../../services";
import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import getExecutorsByCarCallsign from "../../../redux/services/Order/getExecutorsByCarCallsign";
import {
	defaultColumnIds,
	OrderPageExecutorColumnId,
} from "../../../redux/reducers/Orders/Executors/tableSettings";
import useExecutorsActions from "../../../hooks/useExecutorsActions";
import { useExecutorsSubHandler } from "../../../hooks";
import { SVGgeneral } from "../../../utils/generalSprite";
import { StyledRow, StyledColumn, StyledGrid } from "../../common";
import TableSettings from "../../TableSettings";
import SearchBarComponent from "../../SearchBar";
import { useFilteredExecutors } from "../hook";

import { getColumns } from "./constants";
import ExecutorModal from "./components/ExecutorModal";
import TableFooter from "./components/TableFooter";
import ModelTable from "./components/ModelTable";
import Header from "./components/Header";
import Filter from "./components/Filter";
import CallExecutorButton from "./components/CallExexcutorButton";

const SearchBar = styled(SearchBarComponent)`
	width: 280px !important;
`;

const DragButtonRoot = styled(Flex)`
	flex-basis: 18px;

	cursor: grab;
`;

function DragButton() {
	const item = useContext(Grid.Item.ItemContext);

	return item?.indexes.length === 1 ? (
		<GridDragArea.GridDragArea indexes={item.indexes}>
			<DragButtonRoot align="center" justify="start">
				<SVGgeneral id="drag_icon" />
			</DragButtonRoot>
		</GridDragArea.GridDragArea>
	) : null;
}

function ExecutorsWidget() {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const { onSaveColumnsIdsTable, setExecutorsSort, setIsSortMap } =
		useExecutorsActions();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const globalLang = useTypedSelector((state) => state.session.language);
	const { criteria } = useTypedSelector(
		(state) => state.orders.executors.filter,
	);
	const { executorsByCarCallsign, executors } = useTypedSelector(
		(state) => state.ordersPageReducer,
	);
	const { columnIds } = useTypedSelector(
		(state) => state.orders.executors.tableSettings,
	);
	const executorsSubscribeBase = useTypedSelector(
		(state) => state.globalState.executors.data.subscription.basic,
	);

	const [selected, setSelected] = useState<Executor.Model | null>();
	const [selectedId, setSelectedId] = useState<number | null>(null);
	const [alias, setAlias] = useState<string>("");
	const [rerenderSelected, setRerenderSelected] = useState<boolean>(true);
	const [showExecutorModal, setShowExecutorModal] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [query, setQuery] = useState<string>("");
	const [prevQuery, setPrevQuery] = useState<string>("");
	const [foundedExecutorId, setFoundedExecutorId] = useState<number | null>(
		-1,
	);

	const columns = useMemo(() => getColumns(t), [t]);

	const onlineStatus = useMemo(() => {
		const online = criteria.online.includes("online");
		const offline = criteria.online.includes("offline");

		if (online && offline) return undefined;
		if (online) return true;
		if (offline) return false;
		return undefined;
	}, [criteria.online]);

	const workingStatus = useMemo(() => {
		const working = criteria.workingStatus.includes("working");
		const notWorking = criteria.workingStatus.includes("notWorking");

		if (working && notWorking) return undefined;
		if (working) return true;
		if (notWorking) return false;
		return undefined;
	}, [criteria.workingStatus]);

	const executorsSubscribe = useMemo(() => {
		const options: Executor.SubscribeOptions = {
			query,
			isOnline: onlineStatus,
			workingStatus,
			statuses: criteria.statuses,
			taxiServiceIds: criteria.taxiServiceIds,
			order: {
				[criteria.order?.column ||
				(OrderPageExecutorColumnId.ONLINE as keyof Executor.SubscribeOptions["order"])]:
					criteria.order?.type || "asc",
			},
		};

		return getAllExecutorsSubHandler(options);
	}, [
		criteria.order?.column,
		criteria.order?.type,
		criteria.statuses,
		criteria.taxiServiceIds,
		getAllExecutorsSubHandler,
		onlineStatus,
		query,
		workingStatus,
	]);

	const filteredExecutors = useFilteredExecutors(
		executorsSubscribe,
		{
			companyIds: criteria.companyIds,
			taxiServiceIds: criteria.taxiServiceIds,
			executorGroupIds: criteria.executorGroupIds,
			fleetIds: criteria.fleetIds,
		},
		{
			companyIds: true,
			taxiServiceIds: true,
			executorGroupIds: true,
			fleetIds: true,
		},
	);

	const closeExecutorModal = () => {
		setShowExecutorModal(false);
		setSelected(null);
		setAlias("");
		setIsEdit(false);
	};

	useEffect(() => {
		setIsSortMap(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSave = async (
		id,
		value,
		executor: Executor.Model,
		isWorking: boolean,
		selectedCar: Car.Model | null,
	) => {
		if (executor.isWorking !== isWorking) {
			await Executor.updateWorkingStatus(
				{ id, workingStatus: isWorking },
				selectedCar?.id ?? null,
			);
			setIsSortMap(true);
		}

		if (value?.reason) {
			const requestData = {
				id,
				reason: value.reason,
				until: value.until,
			};

			if (!value.until) {
				delete requestData.until;
			}
			if (executor.status !== "closed") {
				await Executor.close(requestData);
				setIsSortMap(true);
			}
			closeExecutorModal();
		} else {
			const requestData =
				typeof value === "object"
					? { id, ...value }
					: { id, status: value };

			if (
				executor.status !== value ||
				executor.status !== value?.status ||
				(executor.debt !== value?.debt && value?.debt === false) ||
				(executor.closedBy !== value?.closedBy &&
					value?.closedBy === false)
			) {
				await Executor.update(requestData);
				setIsSortMap(true);
			}
			closeExecutorModal();
		}

		setSelected(null);
		setIsEdit(false);
	};

	const tableRowClassName = useCallback(
		(rowData: Executor.Model) => {
			if (rowData && rowData?.id === selectedId) {
				if (foundedExecutorId === rowData?.id) return "founded";
				if (!rowData.active) return "founded not-active";
				return "founded";
			}

			if (rowData && foundedExecutorId === rowData?.id) {
				if (!rowData.active) return "founded not-active";
				return "founded";
			}

			if (rowData && !rowData.active) return "not-active";

			return "";
		},
		[foundedExecutorId, selectedId],
	);

	const onRowSelect = (rowData: Executor.Model) => {
		if (rowData?.id === selectedId) {
			setSelectedId(null);
		} else {
			setSelectedId(rowData.id);
		}
	};

	const getExecutor = useCallback(
		async (executor: Executor.Model | undefined) => {
			// if (executor?.id) {
			// 	setLoading(true);
			// 	const res = await Executor.getById(executor.id);
			// 	setSelected(res);
			// 	setLoading(false);
			// 	return;
			// }
			setSelected(executor);
		},
		[],
	);

	const onDoubleClick = async (rowData: Executor.Model) => {
		setSelectedId(rowData.id);
		setShowExecutorModal(true);

		const executor = executorsSubscribe.find(
			(executor) => executor.id === rowData.id,
		);

		await getExecutor(executor);
		setIsEdit(true);
	};

	const onSort = useCallback(
		(column, type) => {
			setExecutorsSort({ column, type });
		},
		[setExecutorsSort],
	);

	const onGetExecutor = useCallback(
		(executor: Executor.Model | undefined) => {
			getExecutor(executor);
		},
		[getExecutor],
	);

	const onClickAddExecutor = useCallback(() => {
		setSelected(null);
		setAlias("");
		setShowExecutorModal(true);
		setSelectedId(null);
		setIsEdit(false);
	}, []);

	const onClickEditExecutor = useCallback(async () => {
		setShowExecutorModal(true);
		setIsEdit(true);

		const executor = executorsSubscribe.find(
			(executor) => executor.id === selectedId,
		);

		await getExecutor(executor);
	}, [executorsSubscribe, getExecutor, selectedId]);

	useEffect(() => {
		if (query[0] === "*") {
			const queryWithoutStar = query.slice(1);
			executorsByCarCallsign.forEach((executor) => {
				if (
					executor?.executorToCrews[0]?.crew?.car?.callSign ===
					queryWithoutStar
				) {
					setFoundedExecutorId(executor.id);
					setPrevQuery(queryWithoutStar);
				}
				if (prevQuery !== queryWithoutStar) {
					setFoundedExecutorId(null);
				}
			});
		} else {
			executorsSubscribe?.forEach((executor) => {
				if (executor.alias === query) {
					setFoundedExecutorId(executor.id);
					setPrevQuery(query);
				}
				if (prevQuery !== query) {
					setFoundedExecutorId(null);
				}
			});
		}
	}, [query, prevQuery, executorsSubscribe, executorsByCarCallsign]);

	useEffect(() => {
		if (showExecutorModal && (alias || selectedId)) {
			setIsEdit(true);
		}

		if (!showExecutorModal && isEdit) {
			const executor = executorsSubscribe.find(
				(executor) => executor.id === selectedId,
			);
			getExecutor(executor);
		}
	}, [
		selectedId,
		rerenderSelected,
		alias,
		executors,
		isEdit,
		showExecutorModal,
		executorsSubscribe,
		getExecutor,
	]);

	useEffect(() => {
		if (query[0] === "*") {
			const queryWithoutStar = query.slice(1);
			dispatch(getExecutorsByCarCallsign(queryWithoutStar));
			setIsSortMap(true);
		}
	}, [dispatch, query, setIsSortMap]);

	const getExecutorById = useCallback(
		(id: number | null) => {
			if (!id) return null;
			const exist = executorsSubscribe?.find((item) => item.id === id);
			return exist;
		},
		[executorsSubscribe],
	);

	return (
		<>
			{showExecutorModal && (
				<ExecutorModal
					onClose={closeExecutorModal}
					onSave={onSave}
					// selected={selected}
					_selected={selected}
					selectedId={selectedId}
					alias={alias}
					isEdit={isEdit}
					setAlias={setAlias}
					setSelectedId={setSelectedId}
					setSelected={onGetExecutor}
					// executors={executorsSubscribe}
					setRerenderSelected={setRerenderSelected}
				/>
			)}
			<StyledColumn w="100%" h="100%">
				<Header maxedWidth justify="space-between">
					<StyledRow gap="4px">
						<StyledRow gap="4px" alignItems="center">
							<DragButton />
							<Button.Button
								variant="secondary"
								icon={<SVGgeneral id="addExecutor" />}
								onClick={onClickAddExecutor}
							/>
						</StyledRow>
						<StyledRow gap="4px" alignItems="center">
							<DragButton />
							<Button.Button
								variant="secondary"
								icon={<SVGgeneral id="pencil" />}
								onClick={onClickEditExecutor}
								disabled={!selectedId}
							/>
						</StyledRow>
						<CallExecutorButton
							executor={getExecutorById(selectedId)}
						/>
					</StyledRow>
					<StyledRow
						/* sizes="auto*" */ gap="10px"
						alignItems="center"
					>
						<SearchBar
							value={query}
							onChange={(value) => {
								setQuery(value);
							}}
							placeholder={
								t("orderPageWidgets.executors.find") ?? ""
							}
						/>
						<Filter language={globalLang} />
					</StyledRow>
				</Header>
				<StyledGrid
					rows="1fr auto"
					areas=""
					style={{ overflow: "hidden" }}
					w="100%"
					h="100%"
				>
					<ModelTable
						columnIds={columnIds}
						// columnWidths={columnWidths}
						// handleColumnResize={handleColumnResize}
						onRowSelect={onRowSelect}
						onSort={onSort}
						sort={criteria.order}
						// data={executorsSubscribe}
						data={filteredExecutors}
						tableRowClassName={tableRowClassName}
						onRowDoubleClick={onDoubleClick}
						language={globalLang}
					/>
					<TableFooter executors={executorsSubscribe} />
					<TableSettings
						value={columnIds}
						defaultValue={defaultColumnIds}
						columns={columns}
						onChange={onSaveColumnsIdsTable}
					/>
				</StyledGrid>
			</StyledColumn>
		</>
	);
}

export declare namespace ModelTable {
	interface Sort {
		column: string | undefined;
		type: SortType | undefined;
	}

	interface RequestValue {
		reason?: string;
	}
}

export default ExecutorsWidget;
