import React, { useLayoutEffect } from "react";

import { AdditionalTariff } from "../../services";
import { useTypedDispatch } from "../../redux/store";
import additionalTariffs from "../../redux/reducers/additionalTariffs";
import { useModelSubscribe } from "../../hooks";

const AdditionalTariffLoader: React.FC<AdditionalTariffLoader.Props> = () => {
	const dispatch = useTypedDispatch();

	const taxiServiceData = useModelSubscribe({}, AdditionalTariff);

	useLayoutEffect(() => {
		dispatch(additionalTariffs.actions.setModels(taxiServiceData.models));
	}, [dispatch, taxiServiceData.models]);

	return <></>;
};

declare namespace AdditionalTariffLoader {
	interface Props {}
}

export default AdditionalTariffLoader;
