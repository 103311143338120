import React, { useCallback, useMemo, useState } from "react";
import { Button, Icon } from "uikit";

import { Language } from "../../../services";
import { Popup } from "..";

import Popover from "./components/Popover";

const ExecutorsFilterPanel: React.FC<ExecutorsFilterPanel.Props> = ({
	filter,
	setFilter,
	language,
	onSave,
}) => {
	const isFilterActive = useMemo(() => {
		if (filter.executorIds.length) return true;
		if (filter.executorGroupIds.length) return true;
		if (filter.fleetIds.length) return true;
		if (filter.taxiServiceIds.length) return true;
		if (filter.companyIds.length) return true;

		return false;
	}, [
		filter.executorGroupIds.length,
		filter.executorIds.length,
		filter.fleetIds.length,
		filter.companyIds.length,
		filter.taxiServiceIds.length,
	]);

	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const popoverOpen = useCallback(() => {
		setOpenAdditionalFilters(true);
	}, []);

	const popoverClose = useCallback(() => {
		setOpenAdditionalFilters(false);
	}, []);

	return (
		<>
			<Popup
				open={openAdditionalFilters}
				offset={{ x: -470, y: 40 }}
				styles={{ shadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
				tracker={
					<Button.Button
						style={
							isFilterActive
								? { backgroundColor: "rgb(109,190,255)" }
								: {}
						}
						variant={isFilterActive ? "primary" : "secondary"}
						icon={<Icon id="filters" size={20} />}
						onClick={popoverOpen}
					/>
				}
			>
				<Popover
					language={language}
					filter={filter}
					setFilter={setFilter}
					onClose={popoverClose}
					onSave={onSave}
				/>
			</Popup>
		</>
	);
};

declare namespace ExecutorsFilterPanel {
	type Value = Popover.Value;
	interface Props {
		filter: Value;
		setFilter: React.Dispatch<React.SetStateAction<Value>>;
		language: Language;
		onSave: (data: Value) => void;
	}
}

export default ExecutorsFilterPanel;
