import React, {
	useCallback,
	useMemo,
	useRef,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "uikit";
import styled from "styled-components";

import {
	Language,
	TaxiService,
	Company,
} from "../../../../../../../../../services";
import { useCarBrandSubscription } from "../../../../../../../../../hooks";
import { LabeledField } from "../../../../../../../..";
import { StyledRow } from "../../../../../../../../common";

const Select = styled.div``;

const Content: React.FC<Content.Props> = ({
	language,
	possibleCompanies,
	possibleTaxiServices,
	companyIds,
	taxiServiceIds,
	setCompanyIds,
	setTaxiServiceIds,
	error,
	onChangeError,
}) => {
	const { t } = useTranslation();
	const companySelectRef = useRef<HTMLDivElement>(null);
	const taxiServiceSelectRef = useRef<HTMLDivElement>(null);

	const possibleCompanyOptions = useMemo(
		() =>
			possibleCompanies.map((company) => ({
				key: company.id,
				label: company.name?.[language],
				value: company.id,
			})),
		[language, possibleCompanies],
	);

	const companyIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setCompanyIds(newCompanyIds as number[]);
			onChangeError((prev) => ({ ...prev, company: false }));
		},
		[onChangeError, setCompanyIds],
	);

	const possibleTaxiServiceOptions = useMemo(
		() =>
			possibleTaxiServices.map((taxiService) => ({
				key: taxiService.id,
				label: `${taxiService?.settlement?.[language]} (${taxiService.company?.name?.[language]}) `,
				value: taxiService.id,
			})),
		[language, possibleTaxiServices],
	);

	const taxiServiceIdsOnChange = useCallback(
		(newTaxiServiceIds: MultiSelect.Value) => {
			setTaxiServiceIds(newTaxiServiceIds as number[]);
			onChangeError((prev) => ({ ...prev, taxiService: false }));
		},
		[onChangeError, setTaxiServiceIds],
	);

	const [showCompany, setShowCompany] = useState(false);
	const [showTaxiService, setShowTaxiService] = useState(false);

	const toggleCompany = useCallback(() => {
		setShowCompany((prev) => !prev);
		setShowTaxiService(false);
	}, []);

	const toggleTaxiService = useCallback(() => {
		setShowTaxiService((prev) => !prev);
		setShowCompany(false);
	}, []);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const isOutsideCompanySelect =
				companySelectRef.current &&
				!companySelectRef.current.contains(event.target as Node);
			const isOutsideTaxiServiceSelect =
				taxiServiceSelectRef.current &&
				!taxiServiceSelectRef.current.contains(event.target as Node);

			if (isOutsideCompanySelect && showCompany) {
				setShowCompany(false);
			}

			if (isOutsideTaxiServiceSelect && showTaxiService) {
				setShowTaxiService(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showCompany, showTaxiService]);

	return (
		<StyledRow gap="12px" w="460px">
			<LabeledField
				label={
					t(
						"orderPageWidgets.chat.executorsFilterPanel.popover.content.str0",
					) ?? ""
				}
			>
				<Select ref={companySelectRef}>
					<MultiSelect
						value={companyIds}
						all
						open={showCompany}
						focused={showCompany}
						onFocus={toggleCompany}
						placeholder={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.content.str1",
							) ?? ""
						}
						error={error.company}
						options={possibleCompanyOptions}
						onChange={companyIdsOnChange}
					/>
				</Select>
			</LabeledField>
			<LabeledField
				label={
					t(
						"orderPageWidgets.chat.executorsFilterPanel.popover.content.str2",
					) ?? ""
				}
			>
				<Select ref={taxiServiceSelectRef}>
					<MultiSelect
						value={taxiServiceIds}
						all
						open={showTaxiService}
						focused={showTaxiService}
						onFocus={toggleTaxiService}
						placeholder={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.content.str3",
							) ?? ""
						}
						error={error.taxiService}
						options={possibleTaxiServiceOptions}
						onChange={taxiServiceIdsOnChange}
					/>
				</Select>
			</LabeledField>
		</StyledRow>
	);
};

declare namespace Content {
	interface Value extends Pick<useCarBrandSubscription.Filter, "activity"> {}

	interface Props {
		language: Language;

		possibleCompanies: Company.Model[];
		possibleTaxiServices: TaxiService.Model[];

		companyIds: number[];
		taxiServiceIds: number[];
		setCompanyIds: (newCompanyIds: number[]) => void;
		setTaxiServiceIds: (newTaxiServiceIds: number[]) => void;

		error: {
			taxiService: boolean;
			company: boolean;
		};
		onChangeError: React.Dispatch<
			React.SetStateAction<{
				taxiService: boolean;
				company: boolean;
			}>
		>;
	}
}

export default Content;
