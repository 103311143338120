/**
 * Filters an text based on the leading asterisks in the query.
 *
 * @param {string} text - The text to be evaluated.
 * @param {string} query - The query string which may contain leading asterisks.
 * @returns {boolean} - Returns true if the text matches the query criteria; otherwise, false.
 */
const filterByLeadingStars = (text: string, query: string): boolean => {
	// Ensure both text and query are strings
	if (typeof text !== "string" || typeof query !== "string") {
		return false;
	}

	// Determine the number of leading asterisks in the query
	const leadingStarsCount = query.match(/^(\*+)/)?.[1].length || 0;

	// If there are no leading asterisks, check if text starts with the query
	if (leadingStarsCount === 0) {
		return text.startsWith(query);
	}

	// If text is shorter than or equal to the number of leading asterisks, it cannot be a match
	if (text.length <= leadingStarsCount) {
		return false;
	}

	// Remove the leading asterisks from the query and the corresponding number of characters from text
	const queryWithoutStars = query.slice(leadingStarsCount);
	const textWithoutStars = text.slice(leadingStarsCount);

	// Check if the adjusted text starts with the adjusted query
	return textWithoutStars.startsWith(queryWithoutStars);
};
export default filterByLeadingStars;
