import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, TextSelect } from "uikit";

import { getExecutorFullName } from "../../../../../Executors/components/ExecutorModal/utils";
import { Executor } from "../../../../../../../services";
import { useTypedSelector } from "../../../../../../../redux/store";
import { useExecutorsSubHandler } from "../../../../../../../hooks";
import { normalizeExecutorObject } from "../../../../utils";

const SearchField: React.FC<any> = ({
	query,
	error,
	onChangeError,
	onChangeQuery,
	onChangeSearchFieldData,
	tabIndex,
	isEditModal,
	parkingData,
	taxiServiceData,
	onChangeTabIndex,
	taxiServiceId,
	label,
	onChangeLabel,
}) => {
	const { t } = useTranslation();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const lang = useTypedSelector((state) => state.session.language);
	const [focusOnField, setFocusOnField] = useState<boolean>(!isEditModal);
	const [firstLoad, setFirstLoad] = useState(false);

	const searchAliasModelData = useMemo(() => {
		const options: Executor.SubscribeOptions = {
			query,
		};
		options.taxiServiceIds = parkingData?.taxiServiceId
			? [parkingData?.taxiServiceId]
			: [taxiServiceId];

		return getAllExecutorsSubHandler(options);
	}, [
		getAllExecutorsSubHandler,
		parkingData?.taxiServiceId,
		query,
		taxiServiceId,
	]);

	const searchAliasModelItems = useMemo(
		() => (query.length ? searchAliasModelData : []),
		[query.length, searchAliasModelData],
	);

	const onSelectExecutor = useCallback(
		(executor) => {
			onChangeSearchFieldData(normalizeExecutorObject(executor.value));
			onChangeQuery(executor.value.alias);
			onChangeLabel(executor.label);
			setFocusOnField(false);
			onChangeTabIndex(1);
		},
		[
			onChangeLabel,
			onChangeQuery,
			onChangeSearchFieldData,
			onChangeTabIndex,
		],
	);

	const executorOptions = useMemo(() => {
		const options = searchAliasModelItems?.map((executor, index) => {
			const labelString = getExecutorFullName(executor);
			const taxiService = taxiServiceData?.cache.find(
				(taxiService) => taxiService.id === executor?.taxiService?.id,
			);
			const companyAndTaxiService = `${taxiService?.company?.name?.[lang]} (${taxiService?.settlement?.[lang]})`;
			return {
				key: index,
				label: `${labelString} (${companyAndTaxiService})`,
				value: executor,
			};
		});

		const filtredOptions = options.filter((option) =>
			option.value.alias.includes(query),
		);

		return filtredOptions;
	}, [query, searchAliasModelItems, taxiServiceData?.cache, lang]);

	// useEffect(() => {
	// 	if (!searchAliasModelData.loading) {
	// 		if (!searchAliasModelData.metadataState.total && query) {
	// 			if (firstLoad) {
	// 				onChangeError(true);
	// 			}
	// 		} else {
	// 			onChangeError(false);
	// 			setFirstLoad(true);
	// 		}
	// 	}
	// }, [
	// 	firstLoad,
	// 	onChangeError,
	// 	query,
	// 	searchAliasModelData.loading,
	// 	searchAliasModelData.metadataState.total,
	// 	searchAliasModelItems.length,
	// ]);

	const onFocus = useCallback(() => {
		setFocusOnField(true);
		onChangeTabIndex(0);
	}, [onChangeTabIndex]);

	useEffect(() => {
		if (tabIndex === 0) {
			onFocus();
		} else {
			setFocusOnField(false);
		}
	}, [onFocus, tabIndex]);

	return (
		<Row gaps="10px" maxedWidth align="center" justify="space-between">
			<span>
				{t(
					"orderPageWidgets.parking.parkingsModal.executorSearchField.str0",
				) ?? ""}
				:
			</span>
			<TextSelect
				value={label || query}
				onChange={onChangeQuery}
				onSelect={onSelectExecutor}
				onFocus={onFocus}
				error={error}
				placeholder={
					t(
						"orderPageWidgets.parking.parkingsModal.executorSearchField.str0",
					) ?? ""
				}
				closeOnSelect
				focused={focusOnField}
				style={{ width: 390 }}
				options={executorOptions}
				key="executorSearch"
			/>
		</Row>
	);
};

export default SearchField;
