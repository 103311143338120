import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Executor, Dispatcher } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { ordersAction as orders } from "../../../../../../../../../../redux/reducers/Orders";
import {
	useExecutorsSubHandler,
	useModelSubscribeOld,
} from "../../../../../../../../../../hooks";
import { SortingOrder } from "../../../../../../../../../../types/SortingOrder";

const ChatsSubscribe: React.FC = () => {
	const dispatch = useDispatch();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const { chats } = useTypedSelector((state) => state.orderPageFilters);

	const executorsData = useMemo(() => {
		const options: Executor.SubscribeOptions = {
			query: chats.filters.query,
		};

		if (chats.filters.order?.column && chats.filters.order?.type) {
			options.order = {
				[chats.filters.order.column]:
					chats.filters.order.type.toUpperCase(),
			};
		}

		return getAllExecutorsSubHandler(options);
	}, [
		chats.filters.order?.column,
		chats.filters.order?.type,
		chats.filters.query,
		getAllExecutorsSubHandler,
	]);

	const dispatcherSubscriptionOptions =
		useMemo<Dispatcher.SubscribeOptions>(() => {
			const result: Dispatcher.SubscribeOptions = {
				query: chats.filters.query,
			};

			if (chats.filters.order?.column && chats.filters.order?.type) {
				if (chats.filters.order?.column === "callSign") {
					result.order = {
						alias: chats.filters.order?.type.toUpperCase() as SortingOrder,
					};
				}
				if (chats.filters.order?.column === "name") {
					result.order = {
						fullName:
							chats.filters.order?.type.toUpperCase() as SortingOrder,
					};
				}
				if (chats.filters.order?.column === "phone") {
					result.order = {
						phone: chats.filters.order?.type.toUpperCase() as SortingOrder,
					};
				}
			}

			return result;
		}, [
			chats.filters.order?.column,
			chats.filters.order?.type,
			chats.filters.query,
		]);

	const dispatchersData = useModelSubscribeOld(
		dispatcherSubscriptionOptions,
		Dispatcher,
	);

	useEffect(() => {
		dispatch(orders.chats.chat.setExecutorsInUserModalData(executorsData));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [executorsData]);

	useEffect(() => {
		dispatch(
			orders.chats.chat.setDispatchersInUserModalData(
				dispatchersData?.cache || [],
			),
		);
	}, [dispatch, dispatchersData?.cache]);

	return null;
};

export default ChatsSubscribe;
