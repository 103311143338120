import React, { useLayoutEffect, useMemo } from "react";

import { OrderSettings } from "../../services";
import { useModelSubscribe } from "../../hooks";
import { useTypedDispatch } from "../../redux/store";
import order from "../../redux/reducers/settings/order";

const OrderSettingsLoader: React.FC<OrderSettingsLoader.Props> = () => {
	const dispatch = useTypedDispatch();

	const options = useMemo(() => {
		const payload: OrderSettings.SubscribeOptions = {
			includeTaxiServices: true,
		};
		return payload;
	}, []);

	const { models } = useModelSubscribe(options, OrderSettings);

	useLayoutEffect(() => {
		const orderSettings = models?.[0];
		if (orderSettings) {
			dispatch(order.actions.set(orderSettings.settings));
		}
	}, [dispatch, models]);

	return <></>;
};

declare namespace OrderSettingsLoader {
	interface Props {}
}

export default OrderSettingsLoader;
