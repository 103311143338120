import createLogger from "../../utils/logger.util";
import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import { PaymentAccount } from "../../types/PaymentAccount";
import { Person, Base, Language, TaxiService, ExecutorRate } from "..";

const logger = createLogger({ name: "ExecutorReport" });

class ExecutorReport extends Base {
	static fromResponse(data: any): ExecutorReport.Model {
		return {
			id: data.id,
			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,

			callSign: data.callSign,
			person: data.person ? Person.fromResponse(data.person) : undefined,
			taxiService: TaxiService.fromResponse(data.taxiService),
			fleet: data.fleet,
			group: data.group,

			ordersCounters: data.ordersCounters,
			executorRateColumns: data.executorRateColumns,
			paymentAccounts: data.paymentAccounts || [],

			totalCommission: data.totalCommission,
			totalSubscription: data.totalSubscription,

			manualReplenishmentAmount: data.manualReplenishmentAmount,
			manualReplenishmentDate: data.manualReplenishmentDate,
			manualReplenishmentPeriod: data.manualReplenishmentPeriod,
			cardReplenishmentAmount: data.cardReplenishmentAmount,
			cardReplenishmentDate: data.cardReplenishmentDate,

			discountCompensation: data.discountCompensation,
			dueAmount: data.dueAmount,
		};
	}

	public static async getExecutorReport(
		params: ExecutorReport.SearchOptions,
	): Promise<ExecutorReport.GetExecutorReportResponse | null> {
		try {
			logger.info("[ExecutorReport] getExecutorReport params", {
				params,
			});
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getExecutorsReport(params),
			);

			logger.info("[ExecutorReport] getExecutorReport response", {
				response,
			});
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			logger.error("[ExecutorReport] Error", error);

			return null;
		}
	}

	public static async getClassifier(): Promise<IResponseWithItems<ExecutorReport.ExecutorRateColumn> | null> {
		try {
			const response = await this.request((prpc) =>
				prpc.theirsModel.executorRate.classifier.getAll(),
			);

			logger.info("[ExecutorReport] getClassifier", { response });

			if (!response) return null;

			return response;
		} catch (error) {
			logger.error("[ExecutorReport] Error", error);

			return null;
		}
	}
}

enum ExecutorRateColumnsType {
	Commission = "commission",
	Subscription = "subscription",
}

declare namespace ExecutorReport {
	interface Model {
		id: number;
		createdAt?: string;
		updatedAt?: string;
		deletedAt?: string | null;

		fleet: ExecutorReport.Model.Fleet | null;
		group: ExecutorReport.Model.Group | null;
		taxiService: TaxiService.Model;

		person?: Person.Model;
		callSign: string;
		ordersCounters: ExecutorReport.Model.OrdersCounters;
		paymentAccounts?: ExecutorReport.Model.PaymentAccountReport[];
		executorRateColumns: ExecutorRateColumn[];

		/** Collects all manual (cash) replenishment (by dispatcher) to the executor main balance */
		manualReplenishmentAmount: number;
		/** Latest manual replenishment date */
		manualReplenishmentDate: Date;
		/** Manual replenishment amount at the end of period */
		manualReplenishmentPeriod: number;

		/** Collects all card replenishment from executor */
		cardReplenishmentAmount: number;
		/** Latest executor's card top up date */
		cardReplenishmentDate: Date;

		/** The total commission is the sum of the all types of the commission (cash, commission, etc) */
		totalCommission: number;
		/** The total subscription is the sum of the all types of the subscription (rent, subscription, additional subscription, etc) */
		totalSubscription: number;
		/** Compensation of the all discounted orders to executor */
		discountCompensation: number;
		/** The amount that the executor must paid to the company */
		dueAmount: number;

		// this is added inside the table and is only needed for the front end!
		rate?: Pick<ExecutorRate.Model, "amountOfMoneyToBlock" | "active">;
	}

	interface ExecutorRateColumn extends Record<string, any> {
		id: number;
		amount: number;
		type: ExecutorRateColumnsType;
		name: Record<Language, string>;
	}

	interface Counters {
		orders: ExecutorReport.Model.OrdersCounters;
		executorRateColumns: ExecutorRateColumn[];
		totalCommission?: number;
		totalSubscription?: number;
		discountCompensation: number;
		dueAmount: number;
		callSign: string;
	}

	interface GetExecutorReportResponse extends IResponseWithItems<Model> {
		counters: Counters;
	}

	interface SearchOptions {
		callSigns?: string[];
		fleetIds?: number[];
		executorIds?: number[];
		executorGroupIds?: number[];
		taxiServiceIds?: number[];
		dateRange: DateRange;
	}

	namespace Model {
		interface PaymentAccountReport extends PaymentAccount {
			amountUpdatedAt: number;
			amountAtEndOfPeriod: number;
		}
		interface Fleet {
			id: number;
			active: boolean;
			name: string;
		}
		interface Group {
			id: number;
			active: boolean;
			executorIds: number[];
			name: Record<Language, string>;
			configuration: Record<string, any>;
		}
		interface AmountCount {
			count: number;
			amount: number;
		}

		interface Penalties extends AmountCount {}

		interface Cash extends AmountCount {
			type: "cash";
		}
		interface CashLess extends AmountCount {
			type: "cashless";
		}
		interface Card extends AmountCount {
			type: "card";
		}
		interface CashLess extends AmountCount {
			type: "cashless";
		}
		interface Overall extends AmountCount {}
		interface Bonus extends AmountCount {}

		interface OrdersCounters {
			cash: Cash;
			cashless: CashLess;
			overall: Overall;
			bonus: Bonus;
			/** card === cardToTaxiService */
			card: Card;
			cardToTaxiService: AmountCount;
			cardToExecutor: AmountCount;
			terminal: AmountCount;
			invoice: AmountCount;
		}
	}
}

export default ExecutorReport;
