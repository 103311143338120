import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "uikit";

import { Language, TaxiService, Company } from "../../../../../../../services";
import { useCarBrandSubscription } from "../../../../../../../hooks";
import { LabeledField } from "../../../../../..";
import { StyledRow } from "../../../../..";

const Content: React.FC<Content.Props> = ({
	language,
	possibleCompanies,
	possibleTaxiServices,
	companyIds,
	taxiServiceIds,
	setCompanyIds,
	setTaxiServiceIds,
	error,
	onChangeError,
}) => {
	const { t } = useTranslation();
	const possibleCompanyOptions = useMemo(
		() =>
			possibleCompanies.map((company) => ({
				key: company.id,
				label: company.name?.[language],
				value: company.id,
			})),
		[language, possibleCompanies],
	);

	const companyIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setCompanyIds(newCompanyIds as number[]);
			onChangeError((prev) => ({ ...prev, company: false }));
		},
		[onChangeError, setCompanyIds],
	);

	const possibleTaxiServiceOptions = useMemo(
		() =>
			possibleTaxiServices.map((taxiService) => ({
				key: taxiService.id,
				label: `${taxiService?.settlement?.[language]} (${taxiService.company?.name?.[language]}) `,
				value: taxiService.id,
			})),
		[language, possibleTaxiServices],
	);

	const taxiServiceIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setTaxiServiceIds(newCompanyIds as number[]);
			onChangeError((prev) => ({ ...prev, taxiService: false }));
		},
		[onChangeError, setTaxiServiceIds],
	);

	const [showCompany, setShowCompany] = useState(false);
	const [showTaxiService, setShowTaxiService] = useState(false);
	const openCompany = useCallback(() => {
		setShowCompany(true);
	}, []);

	const closeCompany = useCallback(() => {
		setShowCompany(false);
	}, []);

	const openTaxiService = useCallback(() => {
		setShowTaxiService(true);
	}, []);

	const closeTaxiService = useCallback(() => {
		setShowTaxiService(false);
	}, []);

	const onFocusCompany = useCallback(() => {
		openCompany();
		closeTaxiService();
	}, [closeTaxiService, openCompany]);

	const onFocusTaxiService = useCallback(() => {
		openTaxiService();
		closeCompany();
	}, [closeCompany, openTaxiService]);

	return (
		<StyledRow gap="12px" w="460px">
			<LabeledField
				label={
					t("common.executorsFilterPanel.popover.content.str0") ?? ""
				}
			>
				<MultiSelect
					value={companyIds}
					all
					open={showCompany}
					focused={showCompany}
					onFocus={onFocusCompany}
					onBlur={closeCompany}
					placeholder={
						t("common.executorsFilterPanel.popover.content.str1") ??
						""
					}
					error={error.company}
					options={possibleCompanyOptions}
					onChange={companyIdsOnChange}
				/>
			</LabeledField>
			<LabeledField
				label={
					t("common.executorsFilterPanel.popover.content.str2") ?? ""
				}
			>
				<MultiSelect
					value={taxiServiceIds}
					all
					open={showTaxiService}
					focused={showTaxiService}
					onFocus={onFocusTaxiService}
					onBlur={closeTaxiService}
					placeholder={
						t("common.executorsFilterPanel.popover.content.str3") ??
						""
					}
					error={error.taxiService}
					options={possibleTaxiServiceOptions}
					onChange={taxiServiceIdsOnChange}
				/>
			</LabeledField>
		</StyledRow>
	);
};

declare namespace Content {
	interface Value extends Pick<useCarBrandSubscription.Filter, "activity"> {}

	interface Props {
		language: Language;

		possibleCompanies: Company.Model[];
		possibleTaxiServices: TaxiService.Model[];

		companyIds: number[];
		taxiServiceIds: number[];
		setCompanyIds: (newCompanyIds: number[]) => void;
		setTaxiServiceIds: (newTaxiServiceIds: number[]) => void;

		error: {
			taxiService: boolean;
			company: boolean;
		};
		onChangeError: React.Dispatch<
			React.SetStateAction<{
				taxiService: boolean;
				company: boolean;
			}>
		>;
	}
}

export default Content;
