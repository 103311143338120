import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useState,
	memo,
} from "react";
import { useTranslation } from "react-i18next";
import {
	CheckBox,
	Stepper,
	MultilineTextBox,
	theme,
	Button as KitButton,
	Icon,
} from "uikit";
import moment from "moment";

import { Executor, Car } from "../../../../../services";
import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import { OrderPageExecutorColumnId } from "../../../../../redux/reducers/Orders/Executors/tableSettings";
import { useExecutorsSubHandler, useKeyBind } from "../../../../../hooks";
import FieldsContainer from "../../../../FieldsContainer";
import LightInputModal from "../../../../LightInputModal";
import {
	CheckBoxWithText,
	StyledColumn,
	StyledP,
	StyledRow,
	StyledSpan,
	SuspenseLoader,
	StyledGrid,
	RadioButton,
} from "../../../../common";
import { getExecutorStatuses } from "../../constants";

import { getCarInformation } from "./utils";
import Root from "./components/Root";
import SearchExecutor from "./components/SearchExecutor";
import CarModal from "./components/CarModal";

enum ExecutorStatus {
	AVAILABLE = "available",
	BUSY = "busy",
	DINNER = "dinner",
	HOME = "home",
	ON_ORDER = "on_order",
	CLOSED = "closed",
}

const ExecutorModal: React.FC<ExecutorModal.Props> = ({
	onClose,
	onSave,
	_selected,
	selectedId,
	alias,
	isEdit,
	setRerenderSelected,
	setAlias,
	setSelected,
	setSelectedId,
}) => {
	const { t } = useTranslation();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const language = useTypedSelector((state) => state.session.language);

	const { criteria } = useTypedSelector(
		(state) => state.orders.executors.filter,
	);

	const onlineStatus = useMemo(() => {
		const online = criteria.online.includes("online");
		const offline = criteria.online.includes("offline");

		if (online && offline) return undefined;
		if (online) return true;
		if (offline) return false;
		return undefined;
	}, [criteria.online]);

	const workingStatus = useMemo(() => {
		const working = criteria.workingStatus.includes("working");
		const notWorking = criteria.workingStatus.includes("notWorking");

		if (working && notWorking) return undefined;
		if (working) return true;
		if (notWorking) return false;
		return undefined;
	}, [criteria.workingStatus]);

	const executorsSub = useMemo(() => {
		const options: Executor.SubscribeOptions = {
			query: criteria.query,
			isOnline: onlineStatus,
			workingStatus,
			statuses: criteria.statuses,
			taxiServiceIds: criteria.taxiServiceIds,
			order: {
				[criteria.order?.column ||
				(OrderPageExecutorColumnId.ONLINE as keyof Executor.SubscribeOptions["order"])]:
					criteria.order?.type || "asc",
			},
		};

		return getAllExecutorsSubHandler(options);
	}, [
		criteria.order?.column,
		criteria.order?.type,
		criteria.query,
		criteria.statuses,
		criteria.taxiServiceIds,
		getAllExecutorsSubHandler,
		onlineStatus,
		workingStatus,
	]);

	const [executors, setExecutors] = useState<Executor.Model[]>([]);

	useEffect(() => {
		if (executorsSub.length > 0 && executors.length === 0) {
			setExecutors(executorsSub);
		}
	}, [executorsSub, executors.length]);

	const EXECUTOR_STATUSES = useMemo(() => getExecutorStatuses(t), [t]);

	const [selected, setSelectedExecutor] = useState<
		Executor.Model | null | undefined
	>(_selected);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		// if (_selected?.id) {
		// 	setLoading(true);

		// 	const fetchData = async () => {
		// 		try {
		// 			const res = await Executor.getById(_selected.id);
		// 			setSelected1(res);
		// 		} finally {
		// 			setLoading(false);
		// 		}
		// 	};

		// 	fetchData();
		// }

		if (selectedId) {
			const fetchData = async () => {
				try {
					const res = await Executor.getById(selectedId);
					setSelectedExecutor(res);
				} catch (err) {
					setSelectedExecutor(_selected);
				}
			};

			fetchData();
		} else {
			setSelectedExecutor(_selected);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedId]);

	const [isDisabledButtons, setIsDisabledButtons] = useState<boolean>(true);
	const [isDataWasChanged, setIsDataWasChanged] = useState<boolean>(false);
	const [isAliasWasSelected, setIsAliasWasSelected] = useState<boolean>(true);

	const [isDebt, setDebt] = useState<boolean>(false);
	const [isClosedBy, setClosedBy] = useState<boolean>(false);

	const [selectedCar, setSelectedCar] = useState<Car.Model | null>(null);

	const [closeWithDelay, setCloseWithDelay] = useState<boolean>(false);

	const [requestData, setRequestData] = useState<any>(selected?.status);

	const [reason, setReason] = useState<string>("");

	const [until, setUntil] = useState<number>(2);
	const [isReasonError, setIsReasonError] = useState<boolean>(false);
	const [isCarNotSelected, setIsCarNotSelected] = useState(false);
	const [secondStatusData, setSecondStatusData] = useState("");

	const showDebt = useMemo(() => Boolean(selected?.debt), [selected]);
	const showClosedBy = useMemo(() => Boolean(selected?.closedBy), [selected]);
	const unlocking = useMemo(
		() => selected?.rate?.amountOfMoneyToUnblock,
		[selected],
	);
	const paymentAccount = useMemo(
		() => selected?.paymentAccounts?.find((item) => item?.type === "main"),
		[selected],
	);

	const isStatusClosed = useMemo(
		() =>
			typeof requestData === "object" &&
			Object.keys(requestData).includes("reason"),
		[requestData],
	);

	const disabledDebt = useMemo(() => {
		if (!unlocking || !paymentAccount) return false;
		return unlocking?.active && paymentAccount?.amount < unlocking.value;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelect = useCallback(
		(item) => {
			setSelectedId(item.value.id);
			setAlias(item.label);
			setRerenderSelected((prev) => !prev);

			const executor = executors.find(
				(executor) => executor.id === item.value?.id,
			);

			setSelected(executor);

			setCloseWithDelay(false);
			setIsAliasWasSelected(true);
			setSelectedCar(null);
			setIsCarNotSelected(false);
		},
		[setSelectedId, setAlias, setRerenderSelected, executors, setSelected],
	);

	const [mainStatusData, setMainStatusData] = useState<boolean>(
		selected?.isWorking ?? false,
	);

	const openCarModal = useCallback(() => {
		setShowCarModal(true);
	}, []);

	const onClickSecondStatus = useCallback(
		(status) => {
			if (status === selected?.status) {
				//
			} else {
				// eslint-disable-next-line no-lonely-if
				if (!isDisabledButtons) {
					if (status === ExecutorStatus.CLOSED) {
						setRequestData({ reason });
						setIsDataWasChanged(true);
						setSecondStatusData(status);
						return;
					}
					setRequestData({ status });
					setSecondStatusData(status);
					setIsDataWasChanged(true);
				}
			}
		},
		[selected?.status, isDisabledButtons, reason],
	);

	const handelDebt = useCallback(
		(value: boolean) => {
			setDebt(value);
			if (requestData?.status || Array.isArray(requestData?.reason))
				return;
			if (!isClosedBy) setIsDataWasChanged(value);
		},
		[isClosedBy, requestData],
	);

	const handelClosedBy = useCallback(
		(value: boolean) => {
			setClosedBy(value);
			if (requestData?.status || Array.isArray(requestData?.reason))
				return;
			if (!isDebt) setIsDataWasChanged(value);
		},
		[isDebt, requestData],
	);
	const onClickMainStatusRadio = useCallback(
		(radioButtonNumber) => {
			if (radioButtonNumber === selected?.isWorking) {
				//
			} else {
				// eslint-disable-next-line no-lonely-if
				if (!isDisabledButtons) {
					setMainStatusData(radioButtonNumber);
					setIsDataWasChanged(true);
				}
			}
			if (!selectedCar && (selected?.cars?.length ?? 0) > 1) {
				if (!selected?.isWorking) {
					openCarModal();
				}
			}
		},
		[
			selected?.isWorking,
			selected?.cars?.length,
			selectedCar,
			isDisabledButtons,
			openCarModal,
		],
	);

	const onChangeStepper = useCallback(
		(untilValue) => {
			if (closeWithDelay && isStatusClosed) {
				setUntil(untilValue);
				requestData.until = untilValue;
			}
		},
		[closeWithDelay, isStatusClosed, requestData],
	);

	const onSubmit = useCallback(async () => {
		if (
			isStatusClosed &&
			(!Array.isArray(requestData?.reason) ||
				requestData.reason.length < 1)
		) {
			setIsReasonError(true);
			return;
		}

		if (!selectedId) {
			setIsAliasWasSelected(false);
			return;
		}

		if (!isEdit && !alias && selectedId) {
			setIsAliasWasSelected(false);
			return;
		}

		if (!isDataWasChanged || isDisabledButtons) {
			if (isDebt || isClosedBy) {
				const payload: Record<string, any> =
					typeof requestData === "object" ? requestData : {};

				if (Object.keys(requestData).includes("until")) {
					payload.until = Date.now() + until * 3.6e6;
				}

				if (isDebt) payload.debt = false;
				if (isClosedBy) payload.closedBy = false;
				if (!selected) return;

				await onSave(
					selectedId,
					requestData,
					selected,
					mainStatusData,
					selectedCar,
				);
				setSelectedId(null);
				setIsReasonError(false);
				return;
			}
		}

		if (
			(selected?.cars?.length ?? 0) > 1 &&
			mainStatusData === true &&
			selected?.isWorking === false &&
			!selectedCar
		) {
			setIsCarNotSelected(true);
			return;
		}

		const payload: Record<string, any> =
			typeof requestData === "object" ? requestData : {};

		if (Object.keys(requestData).includes("until")) {
			payload.until = Date.now() + until * 3.6e6;
		}

		if (isDebt) payload.debt = false;
		if (isClosedBy) payload.closedBy = false;
		if (!selected) return;

		await onSave(
			selectedId,
			payload,
			selected,
			mainStatusData,
			selectedCar,
		);
		setSelectedId(null);
		setIsReasonError(false);
	}, [
		isStatusClosed,
		requestData,
		selectedId,
		isEdit,
		alias,
		isDataWasChanged,
		isDisabledButtons,
		selected,
		mainStatusData,
		selectedCar,
		isDebt,
		isClosedBy,
		onSave,
		setSelectedId,
		until,
	]);

	const onSubmitAndLoading = useCallback(async () => {
		setLoading(true);
		try {
			await onSubmit();
		} finally {
			setLoading(false);
		}
	}, [onSubmit]);

	const onChangeCheckbox = useCallback(
		(value: boolean) => {
			setCloseWithDelay(value);
			if (value && isStatusClosed) {
				requestData.until = until;
			} else {
				delete requestData.until;
			}
		},
		[isStatusClosed, requestData, until],
	);

	const dateRange = useMemo(
		() => ({
			from: moment(selected?.closedBy?.closedAt).format(
				"HH:mm DD/MM/yyyy",
			),
			to: moment(selected?.closedBy?.until).format("HH:mm DD/MM/yyyy"),
		}),
		[selected],
	);
	const untilString = useMemo(
		() =>
			`${t("orderPageWidgets.executors.executorModal.str200") ?? ""} ${
				dateRange.from
			} ${t("orderPageWidgets.executors.executorModal.str201") ?? ""} ${
				dateRange.to
			}`,
		[dateRange.from, dateRange.to, t],
	);

	useEffect(() => {
		if (isStatusClosed) {
			if (closeWithDelay) {
				setRequestData({ reason, until });
			} else {
				setRequestData({ reason });
			}
		}
	}, [reason, closeWithDelay, isStatusClosed, until]);

	useEffect(() => {
		if (!selected) return;
		if (Object.keys(selected).length) {
			setIsDisabledButtons(false);
		}
	}, [selected]);

	useEffect(() => {
		if (!selected) return;

		setMainStatusData(selected.isWorking);
		setSecondStatusData(selected.status);
		setRequestData(selected.status);
	}, [selected, handleSelect]);

	// useEffect(() => {
	// 	if (selected && selected?.alias) {
	// 		const alias = getExecutorFullName(selected);
	// 		setAlias(alias);
	// 	} else setAlias(selected?.alias ?? "");
	// }, [selected?.alias]);

	const checkStatusToDisable = useCallback(
		(status: ExecutorStatus) => {
			if (
				!selected ||
				selected?.status === status ||
				(selected?.hasActiveOrder && status !== ExecutorStatus.CLOSED)
			) {
				return true;
			}

			return false;
		},
		[selected],
	);

	const checkWorkingStatusToDisabled = useCallback(
		(status: boolean) => selected?.isWorking === status,
		[selected],
	);

	useKeyBind(["Escape"], (e) => {
		e.stopPropagation();
		onClose();
	});

	useKeyBind(["Enter"], (e) => {
		e.stopPropagation();
		onSubmitAndLoading();
	});

	const [showCarModal, setShowCarModal] = useState(false);

	const closeCarModal = useCallback(() => {
		setShowCarModal(false);
	}, []);

	const onSaveCar = useCallback((car: Car.Model) => {
		setSelectedCar(car);
		setIsCarNotSelected(false);
	}, []);

	const selectedCarData = useMemo(() => {
		if (!selected) {
			return {
				showText: false,
				text: "",
			};
		}

		const selectedCarInfromation = getCarInformation(selectedCar);

		const firstCarInfromtaion = getCarInformation(
			selected?.cars?.length ? selected?.cars[0] : null,
		);

		const currentJournalCar = selected?.executorShiftJournals?.find(
			(car) => (car as any).isCurrent,
		);
		const foundedCarFromExecutorCarList = selected?.cars?.find(
			(car) => car.id === (currentJournalCar as any)?.car?.id,
		);

		const journalCurrentCarInfromation = getCarInformation(
			foundedCarFromExecutorCarList as any,
		);

		if (selected?.cars?.length === 1) {
			return {
				showText: true,
				text: firstCarInfromtaion,
			};
		}
		if ((selected?.cars?.length ?? 0) > 1 && selected?.isWorking) {
			return {
				showText: true,
				text: journalCurrentCarInfromation,
			};
		}
		if ((selected?.cars?.length ?? 0) > 1 && selectedCar) {
			return {
				showText: true,
				text: selectedCarInfromation,
			};
		}
		if ((selected?.cars?.length ?? 0) > 1 && !selectedCar) {
			return {
				showText: true,
				text:
					t("orderPageWidgets.executors.executorModal.str202") ?? "",
			};
		}
		return {
			showText: false,
			text: "",
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCar, selected]);

	const showUnlockedStatus = useMemo(
		() => showDebt || showClosedBy,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selected],
	);
	return (
		<LightInputModal
			title={t("orderPageWidgets.executors.executorModal.str0") ?? ""}
			onClose={onClose}
			style={{
				width: 550,
				boxShadow: "0px 3px 14px rgba(0,0,0,0.2)",
				height: "85vh",
			}}
			onSubmit={onSubmitAndLoading}
			canSubmit={isDataWasChanged}
		>
			<StyledColumn br="5px" h="85vh" overY="scroll" scrollbar>
				{loading && (
					<StyledRow
						w="100%"
						h="100%"
						justify="center"
						alignItems="center"
					>
						<SuspenseLoader loader="5" />
					</StyledRow>
				)}
				{!loading && (
					<Root align="start" gaps="10px 50px 30px">
						<StyledRow /* sizes="1fr" */ w="100%">
							<SearchExecutor
								alias={alias}
								selected={selected}
								isEdit={isEdit}
								selectedId={selectedId}
								handleSelect={handleSelect}
								isAliasWasSelected={isAliasWasSelected}
								setAlias={setAlias}
							/>
						</StyledRow>
						<StyledColumn
							cursor="default"
							gap="25px"
							/* 	style={{ cursor: "default" }} */
						>
							<StyledRow gap="5px" flex={{ wrap: "wrap" }}>
								<StyledSpan>
									{t(
										"orderPageWidgets.executors.executorModal.str203",
									) ?? ""}
									:
								</StyledSpan>
								<StyledSpan>
									{selected?.status === ExecutorStatus.CLOSED
										? `${
												EXECUTOR_STATUSES[
													selected?.status
												]?.label
										  }`
										: EXECUTOR_STATUSES[
												selected?.status ?? ""
										  ]?.label || selected?.status}
								</StyledSpan>
								{selected?.status === ExecutorStatus.CLOSED && (
									<StyledSpan>{`${
										selected?.closedBy?.until
											? untilString
											: moment(
													selected?.closedBy
														?.closedAt,
											  ).format("HH:mm DD/MM/yyyy")
									}`}</StyledSpan>
								)}
								{showDebt && (
									<StyledSpan>
										{t(
											"orderPageWidgets.executors.executorModal.str100",
										) ?? ""}
									</StyledSpan>
								)}
							</StyledRow>
							{selected?.status === ExecutorStatus.CLOSED && (
								<StyledRow gap="5px">
									<span>
										{t(
											"orderPageWidgets.executors.executorModal.str9",
										) ?? ""}
										:
									</span>
									<span>
										{(selected?.closedBy?.reason ||
											t(
												"orderPageWidgets.executors.executorModal.str205",
											)) ??
											""}
									</span>
								</StyledRow>
							)}
						</StyledColumn>
						<StyledColumn w="100%" gap="10px">
							{showUnlockedStatus && (
								<StyledColumn gap="10px" m="10px">
									{showDebt && (
										<>
											{disabledDebt && (
												<StyledP colors="red">
													{t(
														"orderPageWidgets.executors.executorModal.str206",
													) ?? ""}
												</StyledP>
											)}
											<CheckBoxWithText
												disabled={disabledDebt}
												title={
													t(
														"orderPageWidgets.executors.executorModal.str207",
													) ?? ""
												}
												value={isDebt}
												onChange={handelDebt}
											/>
										</>
									)}
									{showClosedBy && (
										<CheckBoxWithText
											title={
												t(
													"orderPageWidgets.executors.executorModal.str209",
												) ?? ""
											}
											value={isClosedBy}
											onChange={handelClosedBy}
										/>
									)}
								</StyledColumn>
							)}
							<FieldsContainer
								labelColor={theme.colors.text_placeholder}
								label={
									t(
										"orderPageWidgets.executors.executorModal.str1",
									) ?? ""
								}
								style={{ cursor: "default" }}
							>
								<StyledRow gap="30px">
									<StyledRow gap="20px" alignItems="center">
										<RadioButton
											value={mainStatusData === true}
											title={
												t(
													"orderPageWidgets.executors.executorModal.str2",
												) ?? ""
											}
											onChange={() =>
												onClickMainStatusRadio(true)
											}
											disabled={
												isDisabledButtons ||
												checkWorkingStatusToDisabled(
													true,
												)
											}
										/>
										<RadioButton
											value={mainStatusData === false}
											title={
												t(
													"orderPageWidgets.executors.executorModal.str3",
												) ?? ""
											}
											onChange={() =>
												onClickMainStatusRadio(false)
											}
											disabled={
												isDisabledButtons ||
												checkWorkingStatusToDisabled(
													false,
												)
											}
										/>
									</StyledRow>
									<StyledRow
										gap="10px"
										justify="right"
										alignItems="center"
										w="100%"
									>
										{selectedCarData.showText && (
											<div
												style={{
													color: isCarNotSelected
														? theme.colors.negative
														: theme.colors.primary,
												}}
											>
												{selectedCarData.text}
											</div>
										)}
										{selected?.cars
											? selected?.cars.length > 1 && (
													<KitButton.Button
														onClick={openCarModal}
														icon={
															<Icon
																id="car"
																size={20}
															/>
														}
														disabled={
															selected?.isWorking ===
															true
														}
													/>
											  )
											: null}
									</StyledRow>
								</StyledRow>
							</FieldsContainer>
						</StyledColumn>
						<StyledGrid gap="8px" areas="" rows="1fr 1fr" w="100%">
							<div>
								<FieldsContainer
									labelColor={theme.colors.text_placeholder}
									label={
										t(
											"orderPageWidgets.executors.executorModal.str4",
										) ?? ""
									}
									style={{ cursor: "default" }}
								>
									<StyledColumn gap="5px">
										<RadioButton
											value={
												secondStatusData ===
												ExecutorStatus.AVAILABLE
											}
											title={
												t(
													"orderPageWidgets.executors.executorModal.str5",
												) ?? ""
											}
											onChange={() =>
												onClickSecondStatus(
													ExecutorStatus.AVAILABLE,
												)
											}
											disabled={
												isDisabledButtons ||
												checkStatusToDisable(
													ExecutorStatus.AVAILABLE,
												)
											}
										/>
										<RadioButton
											onChange={() => {
												onClickSecondStatus(
													ExecutorStatus.BUSY,
												);
											}}
											value={
												secondStatusData ===
												ExecutorStatus.BUSY
											}
											title={
												t(
													"orderPageWidgets.executors.executorModal.str6",
												) ?? ""
											}
											disabled={
												isDisabledButtons ||
												checkStatusToDisable(
													ExecutorStatus.BUSY,
												)
											}
										/>
										<RadioButton
											onChange={() => {
												onClickSecondStatus(
													ExecutorStatus.DINNER,
												);
											}}
											value={
												secondStatusData ===
												ExecutorStatus.DINNER
											}
											title={
												t(
													"orderPageWidgets.executors.executorModal.str7",
												) ?? ""
											}
											disabled={
												isDisabledButtons ||
												checkStatusToDisable(
													ExecutorStatus.DINNER,
												)
											}
										/>
										<StyledRow
											alignItems="center"
											gap="30px"
											h="20.56"
										>
											<RadioButton
												onChange={() => {
													onClickSecondStatus(
														ExecutorStatus.CLOSED,
													);
												}}
												value={
													secondStatusData ===
													ExecutorStatus.CLOSED
												}
												title={
													t(
														"orderPageWidgets.executors.executorModal.str8",
													) ?? ""
												}
												disabled={
													isDisabledButtons ||
													checkStatusToDisable(
														ExecutorStatus.CLOSED,
													)
												}
											/>
											<StyledRow
												alignItems="center"
												gap="8px"
											>
												<CheckBox
													value={closeWithDelay}
													disabled={
														isDisabledButtons ||
														selected?.status ===
															"closed" ||
														!isStatusClosed
													}
													label=""
													onChange={onChangeCheckbox}
												/>
												<Stepper
													min={0}
													value={until}
													onChange={onChangeStepper}
													disabled={
														isDisabledButtons ||
														selected?.status ===
															"closed" ||
														!closeWithDelay ||
														!isStatusClosed
													}
												/>
												<span>
													{t(
														"orderPageWidgets.executors.executorModal.str101",
													) ?? ""}
												</span>
											</StyledRow>
										</StyledRow>
									</StyledColumn>
								</FieldsContainer>
							</div>
							<MultilineTextBox
								tabIndex={-1}
								value={reason}
								style={{
									height: 100,
								}}
								disabled={isDisabledButtons}
								onChange={(value) => {
									setReason(value);
									setIsReasonError(false);
								}}
								error={isReasonError}
								focused={isReasonError}
								placeholder={
									t(
										"orderPageWidgets.executors.executorModal.str9",
									) ?? ""
								}
							/>
						</StyledGrid>
					</Root>
				)}
			</StyledColumn>

			{showCarModal && selected ? (
				<CarModal
					onClose={closeCarModal}
					executor={selected}
					language={language}
					onSave={onSaveCar}
					selectedCar={selectedCar}
				/>
			) : (
				<></>
			)}
		</LightInputModal>
	);
};

declare namespace ExecutorModal {
	interface Props {
		onClose: () => void;
		onSave: (
			id: any,
			value: any,
			executor: Executor.Model,
			isWorking: boolean,
			selectedCar: Car.Model | null,
		) => Promise<void>;

		_selected: Executor.Model | null | undefined;
		selectedId: number | null;
		alias: string;
		isEdit: boolean;
		setRerenderSelected: Dispatch<React.SetStateAction<boolean>>;
		setAlias: React.Dispatch<React.SetStateAction<string>>;
		setSelected: (executor: Executor.Model | undefined) => void;

		setSelectedId: Dispatch<React.SetStateAction<number | null>>;
	}
}

export default memo(ExecutorModal);
